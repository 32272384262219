import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Innovative Technologist and Leader Elevating Sustainable Solutions in Supply Chain and AI Integration'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  With over 25 years of professional experience, my journey has been 
                  anything but linear. I've pivoted my focus roughly every 2-4 years, 
                  collecting an arsenal of tools and perspectives that arm me to tackle 
                  complex business challenges head-on. From building global cloud networks 
                  for tech giants like Oracle to serving as a firefighter in the USMC, 
                  each chapter of my life has ingrained me with unique skills and a 
                  holistic view of systems—whether they're data centers or supply chains.
                </Text>
                <Text variant='p'>
                  In my role at Always Cool Brands, I've melded my expertise in 
                  technology with a deep commitment to sustainability and 
                  consumer welfare. Here, I have revolutionized private labeling and 
                  supply chain engineering by applying cutting-edge tools like LLMs 
                  and LangChain. These advances not only streamline supplier sourcing 
                  but also enhance the transparency and quality of FDA-regulated products. 
                  This work epitomizes my belief in leveraging technology to create 
                  socially and environmentally responsible solutions. 

                </Text>
                <Text variant='p'>
                  Operational excellence is another cornerstone of my professional approach. 
                  At Oracle, I led a Network Reliability Engineering team, a 
                  specialized SRE unit, to a remarkable 58% YoY reduction in 
                  customer outage minutes. This was achieved by harmoniously integrating 
                  Google's SRE model, DevOps principles, Marine Corps leadership, 
                  and proven engineering practices into a cohesive strategy for success.
                </Text>
                <Text variant='p'>
                  My leadership style is deeply influenced by my time in the Marine Corps. 
                  The concept of 'servant leadership' underpins every team I lead, 
                  every project I undertake, and every challenge I confront. This 
                  philosophy is further enriched by a perpetual beginner's mindset, 
                  which helps me stay agile and receptive to change, 
                  thus facilitating my regular shifts in technological focus.
                </Text>
                <Text variant='p'>
                  In my free time, the balance I seek is found in kiteboarding around 
                  the globe, tending to my thriving garden, and immersing myself in 
                  the vibrant food and music scenes in Austin. These activities not 
                  only refresh me but also offer a tangible connection to the 
                  sustainability and community values I advocate for professionally.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='AI & LangChain Integration'>
                <Card variant='paper'>
                  Harnessing the power of AI and LangChain is no longer a futuristic 
                  dream but a present-day reality. My work at Always Cool Brands has 
                  been instrumental in integrating generative AI tooling into 
                  supply chain engineering. The focus is not merely on efficiency 
                  but also on embedding transparency and sustainability into our systems. 
                  LangChain allows us to smartly match suppliers with our 
                  quality and sustainability metrics, reshaping how businesses think 
                  about responsible sourcing.
                </Card>
              </Section>
              <Divider />
              <Section title='Incident Management & Marine Corps Experience'>
                <Card variant='paper'>
                  The values of integrity, discipline, and courage instilled in me 
                  during my time as a firefighter in the Marine Corps continue 
                  to inform my leadership style. These experiences equipped me 
                  with an acute understanding of incident management. I've applied 
                  these principles effectively in business environments, turning 
                  challenges into opportunities for growth and learning. 
                  This ethos was evident in my work leading 
                  Network Reliability Engineering at Oracle, where we saw a 
                  58% YoY reduction in customer outage minutes. 

                </Card>
              </Section>
              <Divider />
              <Section title='Sustainable Innovation in Supply Chain'>
                <Card variant='paper'>
                  As we navigate the exigencies of a rapidly changing world, 
                  the role of sustainable innovation in supply chain management 
                  cannot be overstated. By combining ISO, SQF, and IWA 42 
                  guidelines with advanced data analytics, we're not only 
                  meeting but exceeding compliance standards at Always Cool Brands. 
                  This dedication to sustainability transcends our operational 
                  objectives and becomes a commitment to our consumers and the planet.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
